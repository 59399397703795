.accordionHeader {
    background-color: #c11a83 !important;
    color: white !important;
}

.firstAcc {
    border-radius: 4px 4px 0 0 !important;
}

.lastAcc {
    border-radius: 0 0 4px 4px !important;
}