.CounterNumber {
    width: 140px;
    height: 120px;
    border-radius: 50%;
    font-size: 50px;
    color: #23aef4;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 900;
    fill: transparent;
    stroke-width: 8;
    stroke: #282828;
    transform: translate(5px,5px);
}

@media screen and (max-width: 600px) {
    .CounterNumber {
        width: 120px;
        height: 100px;
        border-radius: 10px;
        font-size: 35px;

    }
}