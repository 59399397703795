.SocialNavIcon {
    font-size: 25px !important;
    margin-left: 10px;
    color: #595c61;
    cursor: pointer;
}

.SocialNavIcon:hover {
    color: #23aef4;
}

/* .facebook{
    color: blue;
}
.whatsapp{
    color: #25D366;
}
.instagram{
    color: #833ab4;
}
.youtube{
    color: #fd1d1d;
} */

.NavLinks, .NavLinksReg {
    color: #595c61;
    font-size: 17px !important;
    cursor: pointer;
    padding: 10px 20px;
    display: flex;
    font-weight: 600 !important;
}



.InsideLink {
    margin-left: 30px !important;
}

.NavLinks:hover {
    cursor: pointer;
    color: #23aef4 !important;
}


.drawer .MuiPaper-root {
    width: 100%;
    max-width: 70vw;
    min-width: 200px;
}

.registration {
    position: relative;
}

.registration:hover .navMenu, .navMenu:hover {
    display: block;
}

.navMenu {
    display: none;
    position: absolute;
    width: max-content;
    background-color: white;
    top: 26px;
    left: -20px;
    list-style-type: none;
    padding: 20px 0 0 0;
    /* box-shadow: 0px 5px 5px 0px rgba(0, 0, 0, 0.1); */
    /* z-index: 10; */
}

.navMenu li {
    width: 200px;
    padding: 15px;
    color: #595c61;
    cursor: pointer;
}

.navMenu li:hover {
    color: #23aef4;
    background-color: rgba(0, 0, 0, 0.05);
}