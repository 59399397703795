.circle {
    height: 80px;
}



@media (max-width: 900px) {
    .circle {
        height: 50px;
    }
}