.MemberCardCont {
    width: 100%;
    background-color: #c11a8310;
    border-radius: 5px;
    padding: 20px 20px 10px 20px;
}

.MemberCardCont h3 {
    margin: 0;
    font-weight: 500;
}

.MemberCardCont>div {
    width: 100%;
}

.MemberFlexHeader {
    margin: 10px 0 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}